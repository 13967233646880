<template>
  <div class="homesss">
    <!-- <div class="head">
      <img class="bg" src="@/static/head.png">
      <div class="name">设备监控系统</div>
      <img @click="toLogin" class="logout" src="@/static/logout.png">
    </div> -->
    <div class="aside">
      <div class="logo">
        <img style="display: block;width: 20rem;" wi src="@/static/logo.png">
      </div>
      <el-menu :unique-opened="true" default-active="1" style="width: 100%;" :router="true" background-color="#001A1F"
        text-color="#fff" active-text-color="#2BC9DE">
        <el-submenu index="1" v-if="Qx.zx">
          <template slot="title">
            <img src="@/static/zxjc.png" style="width: 2.4rem;height: 2.4rem;margin-right: 1rem;">
            <span style="font-size: 2rem">在线监测</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="HjsjIndex1">
              <span style="margin-left: 1rem;font-size: 2rem">环境数据</span>
            </el-menu-item>
            <el-menu-item index="HjsjIndex2">
              <span style="margin-left: 1rem;font-size: 2rem">能源数据</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 项目管理 -->
        <el-menu-item index="XmglIndex" v-if="Qx.xm">
          <img src="@/static/xmgl.png" style="width: 2.4rem;height: 2.4rem;">
          <span style="margin-left: 1rem;font-size: 2rem" slot="title">公司管理</span>
        </el-menu-item>
        <!-- 设备管理 -->
        <el-submenu index="5" v-if="Qx.sb">
          <template slot="title">
            <img src="@/static/sbgl.png" style="width: 2.4rem;height: 2.4rem;margin-right: 1rem;">
            <span style="font-size: 2rem">设备管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="SbglSbxxgl">
              <span style="margin-left: 1rem;font-size: 2rem">设备信息管理</span>
            </el-menu-item>
            <el-menu-item index="SbglXzsbgl">
              <span style="margin-left: 1rem;font-size: 2rem">新增设备管理</span>
            </el-menu-item>
            <el-menu-item index="SbglBjazxx">
              <span style="margin-left: 1rem;font-size: 2rem">编辑安装信息</span>
            </el-menu-item>
            <el-menu-item index="SbglBjazgy">
              <span style="margin-left: 1rem;font-size: 2rem">编辑安装工艺</span>
            </el-menu-item>
            <el-menu-item index="SbglSjdcrz">
              <span style="margin-left: 1rem;font-size: 2rem">数据导出日志</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="2" v-if="Qx.sg">
          <template slot="title">
            <img src="@/static/icon-gj.png" style="width: 2.4rem;height: 2.4rem;margin-right: 1rem;">
            <span style="font-size: 2rem">施工管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="sggl">
              <span style="margin-left: 1rem;font-size: 2rem">施工管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3" v-if="Qx.gj">
          <template slot="title">
            <img src="@/static/jg.png" style="width: 2.4rem;height: 2.4rem;margin-right: 1rem;">
            <span style="font-size: 2rem">告警管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="gjxx">
              <span style="margin-left: 1rem;font-size: 2rem">告警信息</span>
            </el-menu-item>
            <el-menu-item index="gjsz">
              <span style="margin-left: 1rem;font-size: 2rem">告警设置</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4" v-if="Qx.zh">
          <template slot="title">
            <img src="@/static/zh.png" style="width: 2.4rem;height: 2.4rem;margin-right: 1rem;">
            <span style="font-size: 2rem">账号管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="gsgly">
              <span style="margin-left: 1rem;font-size: 2rem">公司管理员</span>
            </el-menu-item>
            <el-menu-item index="sgryzh">
              <span style="margin-left: 1rem;font-size: 2rem">施工人员账号</span>
            </el-menu-item>
            <el-menu-item index="khgly">
              <span style="margin-left: 1rem;font-size: 2rem">客户管理员</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6" v-if="Qx.rk">
          <template slot="title">
            <img src="@/static/rk.png" style="width: 2.4rem;height: 2.4rem;margin-right: 1rem;">
            <span style="font-size: 2rem">入库管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="rkgl">
              <span style="margin-left: 1rem;font-size: 2rem">入库管理</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item index="" v-if="Qx.sj">
          <span @click="sjbf" style="margin-left: 1rem;font-size: 2rem">数据备份</span>
        </el-menu-item>
        <el-menu-item index="">
          <span @click="dp" style="margin-left: 1rem;font-size: 2rem">进入大屏</span>
        </el-menu-item>

      </el-menu>
    </div>
    <div class="main">
      <div class="head">
        <img class="bg" src="@/static/head.png">
        <div class="name">设备监控系统</div>
        <img @click="toLogin" class="logout" src="@/static/logout.png">
      </div>
      <div class="containerxxx">
        <router-view></router-view>
      </div>

    </div>
    <!-- 遮罩弹窗 -->
    <div
      style="width: 100%; height: 100%; background-color: #a3a3a3; opacity: .5; z-index: 99; position: absolute; top: 0; left: 0;right: 0; bottom: 0;"
      v-if="dataGj">

    </div>
    <div class="gjxx" v-if="dataGj">
      <div class="hb">
        告警信息
      </div>
      <div class="qx" @click="dataGj = false">
        <img src="../static/Union.png">
      </div>
      <div class="main1">
        <div class="box" v-for="item in data" @click="xq(item)">
          <div class="m1">
            <div class="l" style="color: rgba(15, 239, 239, 1);">
              设备编号：{{item.serialNumber}}
            </div>
            <div class="r" v-if="item.alarmDuration">
              告警时长:{{item.alarmDuration}}分钟
            </div>
            <div class="r" v-if="!item.alarmDuration">
              告警暂未结束
            </div>
          </div>
          <div class="m1">
            <div class="l" style="color: rgba(15, 239, 239, 1);">
              设备名称：{{item.name}}
            </div>
            <div class="r"></div>
          </div>

          <div class="m1">
            <div class="l" style="color: rgba(255, 255, 255, 0.8);">
              设备位置：{{item.address}}
            </div>
            <div class="r"></div>
          </div>
          <div class="m1">
            <div class="l" style="color: rgba(249, 69, 69, 1);">
              异常内容：{{item.abnormalCause}}
            </div>
            <div class="r" style="width: 22rem; font-size: 2rem;color: #a3a3a3;">
              {{item.startTime}}
            </div>
          </div>
        </div>
      </div>
      <div class="but">
        <div class="left" @click="dataGj = false">
          确认
        </div>
        <div class="right" @click="dataGj = false">
          取消
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  export default {
    name: 'Home',
    data() {
      const item = {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      };
      return {
        tableData: Array(20).fill(item),
        Qx: {
          zx: false,
          xm: false,
          sb: false,
          sg: false,
          gj: false,
          zh: false,
          rk: false,
          sj: false
        },
        list: [],
        dataGj: false,
        data: []
      }
    },
    components: {

    },
    created() {

      console.log('开始');

      // console.log(sessionStorage.getItem("Lg"));
    },
    mounted() {
      this.login()
      this.ToData_gj()
    },
    methods: {
      xq(item){
        this.dataGj = false
				this.$newGet('/warning/record/readRecord/'+item.id).then(res=>{

				})
        this.$router.push('/gjxx1?id='+ item.equipmentId + '&type=' + item.type)
      },
      ToData_gj() {
        let obj = {
          "page": 1,
          "read": 0,
          "size": 100
        }
        console.log('查看告警数据');
        this.$newPost('/warning/record/page', obj).then(res => {
          console.log(res, 'new!!!!');
          if (res.code == 2000) {
            if (res.data.records.length != 0) {
              this.dataGj = true
              this.data = res.data.records
            }
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
      toLogin() {

				this.$newGet('/index/logout').then(res=>{
					// this.$ajax('logout', 'post')
					sessionStorage.clear()
					// this.$router.push('/login')
					this.$router.push('/login')
				})

      },
      dp() {
         window.open(global.posturl.screen)
      },
      sjbf() {
        this.$ajax('sjkbf', 'post').then(res => {
          console.log(res);
          window.open(res.data.sqlUrl)
        })
      },
      login() {
        axios.get(global.posturl.host + '/index/info', {
          headers: {
            'token': sessionStorage.getItem("token")
          },
        }).then(res => {
          // console.log(res.data.data.permissionList, '角色')
          this.list = res.data.data.permissionList
          // this.getInfo()
          this.list.forEach(item => {
            // console.log(item, '开始');
            if (item == "在线监测") {
              this.Qx.zx = true
            } else if (item == "项目管理") {
              this.Qx.xm = true
            } else if (item == "设备管理") {
              this.Qx.sb = true
            } else if (item == "施工管理") {
              this.Qx.sg = true
            } else if (item == "告警管理") {
              this.Qx.gj = true
            } else if (item == "账号管理") {
              this.Qx.zh = true
            } else if (item == "数据库备份") {
              this.Qx.sj = true
            } else if (item == "入库管理") {
              this.Qx.rk = true
            }
          })
          // this.$message({
          //   type: 'success',
          //   message: '成功'
          // });
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .gjxx {
    width: 96rem;
    height: 57.8rem;
    background-color: rgba(25, 37, 52, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    ::-webkit-scrollbar {
      display: none;
    }

    .hb {
      width: 80.2rem;
      height: 5.4rem;
      line-height: 5.4rem;
      margin-top: 2.5rem;
      margin-left: 6.4rem;
      color: #FFFFFF;
      text-align: center;
      font-size: 3.6rem;
    }

    .qx {
      position: absolute;
      top: 4rem;
      right: 5.2rem;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      color: #FFFFFF;
      font-size: 2rem;

      img {
        width: 2rem;
        height: 2rem;
      }
    }

    .main1 {
      width: 100%;
      height: 40rem;
      padding: 1rem 2.8rem;
      overflow: auto;
      box-sizing: border-box;
      font-size: 2.1rem;

      .box {
        width: 90.4rem;
        min-height: 16rem;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(43, 201, 222, 0.8);
        margin-bottom: 1rem;
        cursor:pointer;

        .m1 {
          width: 90rem;
          min-height: 4.4rem;
          display: flex;
          justify-content: space-between;
          color: #FFFFFF;
          margin-bottom: 0.3rem;

          .l {
            width: 67rem;
            min-height: 4.4rem;
            display: flex;
            align-items: center;
          }

          .r {
            width: 22rem;
            min-height: 4.4rem;
            display: flex;
            align-items: center;
            text-align: right;
          }
        }
      }
    }

    .but {
      width: 33.6rem;
      margin-left: 62.4rem;
      height: 4rem;
      display: flex;
      margin-top: 4rem;
      color: #FFFFFF;

      .left {
        width: 13rem;
        height: 4rem;
        background: linear-gradient(114.36deg, #2BC9DE 15.58%, #2B53DE 85.68%);
        border-radius: .4rem;
        text-align: center;
        line-height: 4rem;
        margin-right: 3.6rem;
      }

      .right {
        width: 13rem;
        height: 4rem;
        border: 1px solid rgba(43, 201, 222, 1);
        border-radius: .4rem;
        text-align: center;
        line-height: 4rem;
      }
    }
  }

  .homesss {
    // min-height: 100vh;
    position: relative;
    width: 100%;
    background-color: #55007f;
    display: flex;
  }

  .head {
    position: relative;
    // z-index: 2;
    // left: 31.4rem;
    // right: 0;
    // top: 0;
    width: 100%;
    height: 14.8rem;
    background-color: #192534;

    .bg {
      width: 61.9rem;
      height: 100%;
      margin-left: 2rem;
    }

    .name {
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translate(0, -50%);
      font-weight: 500;
      font-size: 4.8rem;
      color: #2BC9DE;
      text-shadow: 0px 0px 4px rgba(54, 241, 185, 0.45);
    }

    .logout {
      width: 8.2rem;
      height: 4.4rem;
      border-radius: 0.2rem;
      position: absolute;
      right: 4rem;
      top: 5.2rem;
    }
  }

  .aside {
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 16%;
    // bottom: 0;
    min-height: 100vh;
    background-color: #001A1F;

    /deep/ .el-menu {
      border: none;
    }

    .logo {
      width: 100%;
      height: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .main {
    // position: absolute;
    // z-index: 1;
    // margin-left: 31.4rem;
    // top: 0rem;
    // right: 0;
    // bottom: 0;
    position: relative;
    width: 84%;
    min-height: 100vh;
    background-color: #00131E;

    // padding: 2.2rem 2rem;
    .containerxxx {
      // position: relative;
      background-color: #192534;
	  border-top: 0.0625rem solid gray;
      // margin: 2.2rem 2rem;
	  padding: 2rem;
    }
  }
</style>
